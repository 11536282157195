import { PlatformType } from "./models";

export const ENV = process.env.REACT_APP_NODE_ENV;
export const APP_PREFIX = process.env.REACT_APP_PREFIX;
export const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;
export const DD_SERVICE = process.env.REACT_APP_DD_SERVICE;
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const ANALYTIC_BASE_URL = process.env.REACT_APP_ANALYTIC_BASE_URL;
export const WIDGET_EDITOR_URL = process.env.REACT_APP_WIDGET_EDITOR_URL;
export const MAX_SLOT = +(process.env.REACT_APP_MAX_SLOT || 10);
export const APP_PLATFORM = process.env.REACT_APP_PLATFORM as PlatformType;
export const LAUNCH_DARKLY_CLIENT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY;
export const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
