import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAppState, setIFrameLink } from "../app/slice/appSlice";
import { EditorType } from "../app/slice/appSlice.model";
import {
  createVariantAsync,
  deleteVariantAsync,
  selectConditionsState,
  setSelectedVariantId,
  updateVariant,
  updateVariantAsync,
} from "../app/slice/conditionSlice";
import { WIDGET_EDITOR_URL } from "../config";
import { Slot } from "../models";
import useConditions from "./useConditions";

interface VariantReturnType {
  selectedVariant: any;
  handleSelectedVariantUpdate: (id: string) => void;
  handleVariantStatusUpdate: (id: string, oldStatus: any, status: any) => void;
  handleVariantNameUpdate: (id: string, oldName: any, name: any) => void;
  handleAddWidget: (slot: Slot, variantId?: string) => void;
  creatingVariant: boolean;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (value: boolean) => void;
  deletingVariant: boolean;
  handleDeleteVariant: () => void;
}

const useVariants = (): VariantReturnType => {
  const { t } = useTranslation();
  const { success, error } = useToast();
  const dispatch = useAppDispatch();
  const { platformStoreId, activePage } = useAppSelector(selectAppState);
  const { selectedVariantId } = useAppSelector(selectConditionsState);
  const { selectedCondition } = useConditions();
  const selectedVariant = useMemo(
    () => (selectedCondition?.variants || []).find((v: any) => v.id === selectedVariantId),
    [selectedCondition, selectedVariantId],
  );
  const [creatingVariant, setCreatingVariant] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingVariant, setDeletingVariant] = useState<boolean>(false);

  const handleSelectedVariantUpdate = useCallback(
    (id: string) => {
      dispatch(setSelectedVariantId(id));
    },
    [dispatch],
  );

  const handleVariantStatusUpdate = useCallback(
    (id: string, oldStatus: any, status: any) => {
      dispatch(updateVariant({ id, status }));
      dispatch(updateVariantAsync({ id, body: { status } }))
        .unwrap()
        .then(() => success(t("Variant status changed successfully.")))
        .catch(() => {
          error(t("Failed to change variant status."));
          dispatch(updateVariant({ id, status: oldStatus }));
        });
    },
    [dispatch, success, error, t],
  );

  const handleVariantNameUpdate = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateVariant({ id, name }));
      dispatch(updateVariantAsync({ id, body: { name } }))
        .unwrap()
        .then(() => success(t("Variant name changed successfully.")))
        .catch(() => {
          error(t("Failed to change variant name."));
          dispatch(updateVariant({ id, status: oldName }));
        });
    },
    [dispatch, success, error, t],
  );

  const handleAddWidget = useCallback(
    (slot: Slot, variantId?: string) => {
      if (variantId) {
        dispatch(
          setIFrameLink(
            `${WIDGET_EDITOR_URL}?variant=${variantId}${
              EditorType[activePage!] ? `&type=${EditorType[activePage!]}` : ""
            }&platformStoreId=${platformStoreId}`,
          ),
        );
      } else {
        setCreatingVariant(true);
        const body = {
          name: `${selectedCondition?.isDefault ? "Default" : selectedCondition?.name} - ${slot.name.toLocaleLowerCase()}`,
          coverage: 100,
          slotId: slot.id,
          conditionId: selectedCondition?.id,
        };

        dispatch(createVariantAsync(body))
          .unwrap()
          .then(res => {
            console.log({ res });
            dispatch(
              setIFrameLink(
                `${WIDGET_EDITOR_URL}?variant=${res.data.id}${
                  EditorType[activePage!] ? `&type=${EditorType[activePage!]}` : ""
                }&platformStoreId=${platformStoreId}&isNewVariant=true`,
              ),
            );
          })
          .catch(() => error(t("Failed to add variant.")))
          .finally(() => setCreatingVariant(false));
      }
    },
    [dispatch, activePage, platformStoreId, selectedCondition, error, t],
  );

  const handleDeleteVariant = useCallback(() => {
    if (!selectedVariantId) return;

    setDeletingVariant(true);
    dispatch(deleteVariantAsync(selectedVariantId))
      .unwrap()
      .then(() => success(t("Variant deleted successfully.")))
      .catch(() => error(t("Error while deleting variant.")))
      .finally(() => {
        setIsDeleteModalOpen(false);
        setDeletingVariant(false);
      });
  }, [dispatch, success, error, t, selectedVariantId]);

  return {
    selectedVariant,
    handleSelectedVariantUpdate,
    handleVariantStatusUpdate,
    handleVariantNameUpdate,
    handleAddWidget,
    creatingVariant,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingVariant,
    handleDeleteVariant,
  };
};

export default useVariants;
