import { Badge, Box, Button, Card, Center, Divider, Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import { Icons, ToolTip, Typography } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectAppState, toggleHelpModal } from "../../../app/slice/appSlice";
import { CheckoutEditorType, PageType } from "../../../app/slice/appSlice.model";
import { selectConditionsState } from "../../../app/slice/conditionSlice";
import { selectSlotsState } from "../../../app/slice/slotSlice";
import { APP_PLATFORM } from "../../../config";
import useConditions from "../../../hooks/useConditions";
import useSlots from "../../../hooks/useSlots";
import { Platform, Variant } from "../../../models";
import { SlotVariant } from "../../SlotVariant";
import EmptyCard from "./EmptyCard";
import { getAllVariantAnalytics } from "../../../app/slice/analyticsSlice";

const Slots = () => {
  const { isSidebarCollapased, activePage, dateRange, view, checkoutProfileId } = useAppSelector(selectAppState);
  const { loading: loadingSlots, slots, canCreateSlot, creatingSlot } = useAppSelector(selectSlotsState);
  const { loading, loadingCondition, selectedConditionId } = useAppSelector(selectConditionsState);
  const dispatch = useAppDispatch();
  const { selectedCondition } = useConditions();

  const { handleCreateSlot } = useSlots();
  const [isWidth1090] = useMediaQuery("(min-width: 1090px)");
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedConditionId && view.isAnalyticView) {
      dispatch(getAllVariantAnalytics(selectedConditionId));
    }
  }, [dispatch, selectedConditionId, dateRange.startDate, dateRange.endDate, view.isAnalyticView]);

  const variantsBySlot = useMemo(() => {
    const groupedVariants: { [key: string]: Variant[] } = {};

    (selectedCondition?.variants || []).forEach((variant: Variant) => {
      const slotId = variant?.slotId;

      if (!groupedVariants[slotId]) {
        groupedVariants[slotId] = [];
      }

      groupedVariants[slotId].push(variant);
    });

    return groupedVariants;
  }, [selectedCondition]);

  const onGoToEditor = useCallback(() => {
    let queryParams = "";

    if (CheckoutEditorType[activePage!]) {
      queryParams = `?page=${CheckoutEditorType[activePage!]}`;
    }

    if (checkoutProfileId) {
      window.open(
        `https://admin.shopify.com/store/${selectedCondition?.platformStoreId?.replaceAll(".myshopify.com", "")}/settings/checkout/editor/profiles/${checkoutProfileId}${queryParams}`,
      );
    } else {
      window.open(
        `https://${selectedCondition?.platformStoreId}/admin/settings/checkout/editor${queryParams}`,
        "_blank",
      );
    }
  }, [activePage, selectedCondition?.platformStoreId, checkoutProfileId]);

  const headerTitle: Record<PageType, string> = {
    [PageType.CHECKOUT]: "Checkout slots",
    [PageType.PRODUCT_PAGE]: "Checkout slots",
    [PageType.CART_PAGE]: "Cart Page",
    [PageType.POST_PURCHASE_PAGE1]: "Post purchase - 1st page",
    [PageType.POST_PURCHASE_PAGE2]: "Post purchase - 2nd page",
    [PageType.THANK_YOU_PAGE]: "Thank you page",
    [PageType.ORDER_STATUS_PAGE]: "Order status page",
  };

  return (
    <Box pt={7} flex={1} pl={4} pr={"24px"}>
      <Flex
        justifyContent={"space-between"}
        alignItems={isSidebarCollapased ? "center" : "self-start"}
        flexDirection="column"
      >
        <Flex justifyContent={"space-between"} alignItems={"center"} w="100%">
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h2" pr={2} fontWeight={500}>
              {t(headerTitle[activePage!])}
            </Typography>
            <ToolTip
              placement="bottom"
              label={t(
                "Your widgets are stored in 'slots'. Once a condition is met, related slots display on checkout. Use the Shopify Editor to position slots on the checkout page.",
              )}
            >
              <span>
                <Icons.Question />
              </span>
            </ToolTip>
          </Flex>
          <Flex my="auto">
            {canCreateSlot && (
              <Button
                height={"min-content"}
                variant="unstyled"
                onClick={handleCreateSlot}
                display="flex"
                gap={2}
                isDisabled={!selectedCondition}
                isLoading={creatingSlot}
                _loading={{ height: "20px" }}
              >
                <Typography
                  variant="subheading2"
                  fontWeight={500}
                  lineHeight={"20px"}
                  _hover={{
                    borderBottomColor: "inherit",
                    borderBottom: "1px solid",
                  }}
                >
                  {t("Create a slot")}
                </Typography>{" "}
                <Icons.PlusCircle height={"16px"} width={"16px"} />
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex marginTop={"8px"} justifyContent="start" width="100%" alignItems={"center"} gap={4} whiteSpace="nowrap">
          {APP_PLATFORM === Platform.Shopify ? (
            <>
              {activePage === PageType.POST_PURCHASE_PAGE1 || activePage === PageType.POST_PURCHASE_PAGE2 ? (
                <Typography variant="small" color="var(--Link, #2F73DA)" fontWeight={500} as="a" cursor="pointer">
                  {t("Learn more about post purchase pages")}
                </Typography>
              ) : (
                <>
                  <Flex>
                    <Typography
                      variant="small"
                      color="var(--Link, #2F73DA)"
                      fontWeight={500}
                      as="a"
                      pr={"16px"}
                      cursor="pointer"
                      onClick={() =>
                        dispatch(
                          toggleHelpModal({
                            link: "https://help.reconvert.io/en/article/how-to-place-slots-in-the-shopify-editor-8qknth/reader/?bust=1707148053284",
                            title: t("How to place slots in the Shopify editor").toString(),
                          }),
                        )
                      }
                    >
                      {t("How to place a slot?")}
                    </Typography>
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                      <Typography
                        variant="small"
                        as="a"
                        cursor="pointer"
                        color="var(--Link, #2F73DA)"
                        pr={2}
                        fontWeight={500}
                        onClick={onGoToEditor}
                      >
                        {t("Place your slots in the Shopify editor")}
                      </Typography>
                      <Icons.ExternalLink
                        cursor="pointer"
                        onClick={onGoToEditor}
                        className="externalLink"
                        w={4}
                        h={4}
                      />
                    </Flex>
                  </Flex>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      {APP_PLATFORM === Platform.Shopify ? (<Divider orientation="horizontal" mt={"8px"} mb="10px" />) : (<Divider orientation="horizontal" mt={"23px"} mb="10px" />)}
      <Box overflowY={"auto"} height={"calc(100vh - 180px)"} padding={"6px 5px 0px 5px"}>
        {loadingSlots || loading || loadingCondition ? (
          <Center>
            <Spinner variant={"ring"} size={"lg"} mt={20} />
          </Center>
        ) : (
          <>
            {!selectedCondition ? (
              <EmptyCard />
            ) : (
              <Card
                pb={4}
                background="rgba(255, 255, 255, 0.50)"
                borderTop={"1px solid var(--Neutral-400, #DADEE5)"}
                borderTopRadius={"8px"}
                height="auto"
                minHeight={`calc(100vh - ${isWidth1090 ? "186px" : "80px"})`}
                maxWidth={"748px"}
              >
                <Box ml={"-5px"} mt={"8px"}>
                  <Badge colorScheme="var(--Secondary-600, #C78B35)" size="large" variant="sharp" maxWidth={"60%"}>
                    <Typography
                      variant="subheading2"
                      style={{ zIndex: "2", overflow: "hidden", textOverflow: "ellipsis" }}
                      textTransform="uppercase"
                    >
                      {selectedCondition?.name}
                    </Typography>
                  </Badge>
                </Box>

                {slots && slots.length > 0 ? (
                  <>
                    {slots.map((slot: any, index: number) => {
                      return <SlotVariant slot={slot} variants={variantsBySlot[`${slot?.id}`]} key={index} />;
                    })}

                    {canCreateSlot && <Divider orientation="horizontal" mt={"32px"} />}
                    {canCreateSlot && (
                      <Flex justifyContent="center" alignItems="center" mt={6} gap={2} color="neutrals.700">
                        <Button
                          size="sm"
                          variant="outline"
                          padding={"4px 8px"}
                          height={"min-content"}
                          color="neutrals.700"
                          border={"1px solid var(--Neutral-700, #363C45);"}
                          _hover={{
                            color: "neutrals.900",
                            border: "1px solid var(--Neutral-900, #121417)",
                            bg: "var(--Neutral-200, #F7F9FC)",
                          }}
                          _active={{ color: "#ffffff", bg: "neutrals.900" }}
                          gap={1}
                          onClick={handleCreateSlot}
                          display={"flex"}
                          isDisabled={!selectedCondition}
                          isLoading={creatingSlot}
                          _loading={{
                            height: "25px",
                          }}
                        >
                          <Typography variant="xsmall">{t("Create a slot")}</Typography>
                          <Icons.PlusCircle height={"12px"} width={"12px"} />
                        </Button>
                      </Flex>
                    )}
                  </>
                ) : (
                  <Flex flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
                    <Typography variant="subheading1" color="var(--Neutral-600, #6A7381)">
                      {t("Please create a slot")}
                    </Typography>
                    <Button
                      onClick={handleCreateSlot}
                      px={4}
                      mt={4}
                      isLoading={creatingSlot}
                      rightIcon={<Icons.PlusCircle />}
                    >
                      {t("Create a slot")}
                    </Button>
                  </Flex>
                )}
              </Card>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Slots;
